import logo from '~/assets/img/logo.png';
import { useEffect, useState } from 'react';

const DefaultLayout = ({ children, title }) => {
    const [isVisible, setIsVisible] = useState(false);

    // Function to handle scrolling and showing/hiding the button
    const handleScroll = () => {
        if (window.scrollY > 100) {
            // Change 100 to the scroll position you prefer
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Function to scroll back to the top of the page
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="toggle-sidebar">
            <header className="header fixed-top d-flex align-items-center" id="header">
                <div className="d-flex align-items-center justify-content-between">
                    <a className="logo d-flex align-items-center" href="/">
                        <img alt="Logo" src={logo} />
                        <span className="d-none d-lg-block">CĐS Hà Tĩnh</span>
                    </a>
                </div>
            </header>

            <main className="main" id="main" style={{ minHeight: '100vh' }}>
                <div className="pagetitle">
                    <h1>{title}</h1>
                </div>
                <section className="section dashboard">{children}</section>
            </main>

            <footer className="footer" id="footer">
                <div className="copyright">
                    © Bản quyền
                    <strong>
                        <span>Chuyển đổi số Hà Tĩnh</span>
                    </strong>
                </div>
                <div className="credits">
                    Thiết kế
                    <a href="https://bootstrapmade.com/">Trung tâm CNTT&TT Hà Tĩnh</a>
                </div>
            </footer>

            {isVisible && (
                <button
                    onClick={handleScrollToTop}
                    className="back-to-top d-flex align-items-center justify-content-center active"
                >
                    <i className="bi bi-arrow-up-short"></i>
                </button>
            )}
        </div>
    );
};

export default DefaultLayout;
