import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/job-tracking');
    }, [navigate]);

    return <div>Redirecting to the specific page...</div>;
}

export default Home;
