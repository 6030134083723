import React, { useEffect, useRef, useState } from 'react';
import { over } from 'stompjs';
import SockJS from 'sockjs-client/dist/sockjs';
import config from '~/config';
import './GameShow.scss';
import * as destinationService from '~/services/destinationService';
import CountdownTimer from '~/components/CountdownTimer';

const decodeBase64 = (base64) => {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new TextDecoder().decode(bytes);
};

const renderDestination = (html, destination) => {
    const decodeBase64Value = decodeBase64(html);
    destination.current.innerHTML = decodeBase64Value;
};

const GameShow = () => {
    const destination1Ref = useRef(null);
    const destination2Ref = useRef(null);

    const [countdownTimer, setCountdownTimer] = useState({
        visibility: false,
        duration: 15,
        type: 2,
    });
    // Websocket
    useEffect(() => {
        document.title = 'GAME SHOW TRUYỀN HÌNH “NHẬN DIỆN LỪA ĐẢO TRÊN KHÔNG GIAN MẠNG”';
        const socket = new SockJS(`${config.constants.BASE_URL}/ws`);
        const stompClient = over(socket);
        stompClient.connect({}, () => {
            console.log('Connected to WebSocket');
            stompClient.subscribe('/topic/destination-1', (response) => {
                const result = JSON.parse(response.body);
                renderDestination(result.base64Html, destination1Ref);
                destination2Ref.current.innerHTML = '';
            });
            stompClient.subscribe('/topic/destination-2', (response) => {
                const result = JSON.parse(response.body);
                renderDestination(result.base64Html, destination2Ref);
            });
            stompClient.subscribe('/topic/destination-3', (response) => {
                const result = JSON.parse(response.body);
                setCountdownTimer(result);
            });
        });

        return () => {
            stompClient.disconnect();
        };
    }, []);
    // Fetch Data
    useEffect(() => {
        const fetchApi = async () => {
            const result1 = await destinationService.getLastDestinationByFragmentType(1);
            renderDestination(result1.base64Html, destination1Ref);
            const result2 = await destinationService.getLastDestinationByFragmentType(2);
            renderDestination(result2.base64Html, destination2Ref);
        };
        fetchApi();
    }, []);

    return (
        <React.Fragment>
            <div className="row" ref={destination1Ref}></div>
            <div className="row" ref={destination2Ref}></div>
            {countdownTimer.visibility && (
                <CountdownTimer duration={countdownTimer.duration} type={countdownTimer.type} />
            )}
        </React.Fragment>
    );
};

export default GameShow;
