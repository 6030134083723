import React, { useState, useEffect } from 'react';
import * as taskService from '~/services/taskService';
import { over } from 'stompjs';
import SockJS from 'sockjs-client/dist/sockjs';
import config from '~/config';

function Task() {
    const [tasks, setTasks] = useState([]);
    // Add event listener when component mounts
    useEffect(() => {
        const fetchApi = async () => {
            const result = await taskService.getTasks();
            setTasks(result);
        };
        fetchApi();
    }, []);
    // Connect socket
    useEffect(() => {
        const socket = new SockJS(`${config.constants.BASE_URL}/ws`);
        const stompClient = over(socket);

        stompClient.connect({}, () => {
            console.log('Connected to WebSocket');

            stompClient.subscribe('/topic/tasks', (response) => {
                const newMessage = JSON.parse(response.body);
                setTasks(newMessage);
            });
        });

        return () => {
            stompClient.disconnect();
        };
    }, []);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="row" id="tasks">
                        {tasks.map((task, index) => (
                            <div className="col-md-3" key={index}>
                                <div className="card info-card customers-card">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {task.projectName}
                                            <span>&nbsp;| {task.moduleName}</span>
                                        </h5>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="
                                                    rounded-circle
                                                    d-flex
                                                    align-items-center
                                                    justify-content-center"
                                            >
                                                <img
                                                    style={{ width: '50px', height: '50px' }}
                                                    src={task.imageUrl}
                                                    alt="Avatar"
                                                />
                                            </div>
                                            <div className="ms-5">
                                                <p className="fw-bold" style={{ color: '#012970' }}>
                                                    {task.taskName}
                                                </p>
                                                <span className="fw-bold">{task.createdDate}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Task;
