import Home from '~/pages/Home';
import Task from '~/pages/Task';
import JobTracking from '~/pages/JobTracking';
import GameShow from '~/pages/GameShow';
import config from '~/config';
import DefaultLayout from '~/layouts/DefaultLayout';
import GameShowLayout from '~/layouts/GameShowLayout';

const publicRoutes = [
    { path: config.routes.home, component: Home, key: 'home' },
    { path: config.routes.listTask, component: Task, layout: DefaultLayout, title: 'Theo dõi công việc', key: 'task' },
    { path: config.routes.jobTracking, component: JobTracking, key: 'jobTracking' },
    { path: config.routes.gameShow, component: GameShow, layout: GameShowLayout, key: 'gameShow' },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
