import httvLogo from '~/assets/img/httv-logo.png';
import caLogo from '~/assets/img/ca-logo.png';
import sttttLogo from '~/assets/img/stttt-logo.png';
import nhnnvn from '~/assets/img/nhnnvn-logo.png';
import { useEffect, useState } from 'react';
import './GameShowLayout.scss';
import Marquee from 'react-fast-marquee';

const GameShowLayout = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);

    // Function to handle scrolling and showing/hiding the button
    const handleScroll = () => {
        if (window.scrollY > 100) {
            // Change 100 to the scroll position you prefer
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Function to scroll back to the top of the page
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="toggle-sidebar">
            {/* <header className="gameshow-header fixed-top">
                <h1 className="gameshow-title">GAME SHOW TRUYỀN HÌNH “NHẬN DIỆN LỪA ĐẢO TRÊN KHÔNG GIAN MẠNG”</h1>
            </header> */}
            <main className="main" id="main" style={{ minHeight: '100vh', marginTop: '0px' }}>
                <section className="section dashboard">{children}</section>
            </main>

            {/* <footer className="gameshow-footer fixed-bottom d-flex">
                <Marquee>
                    <div className="sponsor-logo d-flex align-items-center">
                        <img alt="Logo" src={httvLogo} />
                        <span>Truyền hình Hà Tĩnh</span>
                    </div>
                    <div className="sponsor-logo d-flex align-items-center ">
                        <img alt="Logo" src={sttttLogo} />
                        <span>Sở Thông tin và Truyền thông</span>
                    </div>
                    <div className="sponsor-logo d-flex align-items-center">
                        <img alt="Logo" src={caLogo} />
                        <span>Công an tỉnh Hà Tĩnh</span>
                    </div>
                    <div className="sponsor-logo d-flex align-items-center">
                        <img alt="Logo" src={nhnnvn} />
                        <span>Ngân hàng nhà nước Việt Nam chi nhánh Hà Tĩnh</span>
                    </div>
                </Marquee>
            </footer> */}

            {isVisible && (
                <button
                    onClick={handleScrollToTop}
                    className="back-to-top d-flex align-items-center justify-content-center active"
                >
                    <i className="bi bi-arrow-up-short"></i>
                </button>
            )}
        </div>
    );
};

export default GameShowLayout;
