import api from '~/helpers/api';

export const getLastDestinationByFragmentType = async (fragmentType) => {
    const res = await api.get('/api/v1/destination/last', {
        params: {
            fragmentType: fragmentType,
        },
    });
    return res.data;
};
