import { useState, useEffect } from 'react';
import { over } from 'stompjs';
import SockJS from 'sockjs-client/dist/sockjs';
import config from '~/config';

const JobTracking = () => {
    const [iframeUrl, setIframeUrl] = useState('');
    const [count, setCount] = useState(1);
    const [isCounting, setIsCounting] = useState(false);

    console.log('render', count);
    useEffect(() => {
        let intervalId;
        if (isCounting) {
            intervalId = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount === 30) {
                        const queryParameters = new URLSearchParams(window.location.search);
                        const id = queryParameters.get('id');
                        setIframeUrl(`https://tdcv.hatinh.gov.vn/lichstt/&lctiddv=${id}`);
                        setIsCounting(false);
                        return 1;
                    } else {
                        return prevCount + 1;
                    }
                });
            }, 1000);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [isCounting]);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const id = queryParameters.get('id');
        document.title = 'LỊCH LÀM VIỆC - SỞ THÔNG TIN VÀ TRUYỀN THÔNG';
        setIframeUrl(`https://tdcv.hatinh.gov.vn/lichstt/&lctiddv=${id}`);
    }, []);

    useEffect(() => {
        const socket = new SockJS(`${config.constants.BASE_URL}/ws`);
        const stompClient = over(socket);

        stompClient.connect({}, () => {
            console.log('Connected to WebSocket');
            stompClient.subscribe('/topic/iframe', (response) => {
                const queryParameters = new URLSearchParams(window.location.search);
                const id = queryParameters.get('id');
                const data = JSON.parse(response.body);
                if (data.iframeUrl && data.id === parseInt(id)) {
                    setCount(1);
                    setIsCounting(true);
                    setIframeUrl(data.iframeUrl);
                }
            });
        });
        return () => {
            stompClient.disconnect();
        };
    }, []);

    return (
        <div>
            <iframe
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    border: 'none',
                }}
                src={iframeUrl}
                title="Lịch công tác và tiến độ công việc"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default JobTracking;
