import { useState, useEffect } from 'react';
import './CountdownTimer.scss';

const CountdownTimer = ({ duration, type }) => {
    const [time, setTime] = useState(duration);

    const formatTime = (time) => {
        const absTime = Math.abs(time);
        const minutes = Math.floor(absTime / 60);
        const seconds = absTime % 60;
        const minusPoints = Math.floor(absTime / 30);
        const displayMinusPoints = `${time <= -30 ? '[-' + minusPoints + ' điểm] ' : ''}`;
        return `${time < 0 ? displayMinusPoints : ''}${minutes < 10 ? '0' : ''}${minutes}:${
            seconds < 10 ? '0' : ''
        }${seconds}`;
    };

    useEffect(() => {
        let timer = null;

        if ((type === 1 && time > 0) || type === 2) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [time, type]);

    return (
        <div className="countdown-wrapper">
            <div className="countdown-container">
                <span className="countdown-time">{formatTime(time)}</span>
            </div>
        </div>
    );
};

export default CountdownTimer;
